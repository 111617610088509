$color-primary: #81c2de;
$color-primary-light: #b4f5ff;
$color-primary-dark: #4f92ac;

$color-white: #fffbfa;

$color-black: #191308;

body {
	background: #262626;
}
.pulsing {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 80px;
	height: 80px;
	text-align: center;
	border-radius: 50%;
	background: white;
	opacity: 0.5;
}
.pulsing:hover {
	opacity: 1;
	transition: 0.5s;
}
.pulsing .fa {
	line-height: 150px;
	font-size: 100px;
	color: #fff;
}
.pulsing::before,
.pulsing::after {
	content: '';
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	border-radius: 50%;
	width: 100%;
	height: 100%;
	border: 2px solid lightblue;
	opacity: 0;
	animation: animate 4s infinite linear;
}
.pulsing::after {
	animation-delay: 2s;
}

.pulsing-mail {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 80px;
	height: 80px;
	text-align: center;
	border-radius: 50%;
	background: white;
	opacity: 0.5;
}
.pulsing-mail:hover {
	opacity: 1;
	transition: 0.5s;
}
.pulsing-mail .fa {
	line-height: 150px;
	font-size: 100px;
	color: #fff;
}
.pulsing-mail::before,
.pulsing-mail::after {
	content: '';
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	border-radius: 50%;
	width: 100%;
	height: 100%;
	border: 2px solid orange;
	opacity: 0;
	animation: animate 4s infinite linear;
}
.pulsing-mail::after {
	animation-delay: 2s;
}

@keyframes animate {
	0% {
		width: 100%;
		height: 100%;
		opacity: 1;
	}
	100% {
		width: 250%;
		height: 250%;
		opacity: 0;
	}
}

.floating {
	animation-name: floating;
	animation-duration: 3s;
	animation-iteration-count: infinite;
	animation-timing-function: ease-in-out;
	margin-left: 30px;
	margin-top: 5px;
}

@keyframes floating {
	from {
		transform: translate(0, 0px);
	}
	65% {
		transform: translate(0, 30px);
	}
	to {
		transform: translate(0, -0px);
	}
}

.button-fit {
	width: 100% !important;
	height: 100% !important;
}

.button-area {
	width: 40%;
	height: 50%;
	top: 20%;
	right: 0%;
	position: absolute;
}

.btn-linkedin {
	top: 10%;
	position: absolute;
	z-index: 10;
}

.btn-mail {
	top: 30%;
	left: 40%;
	position: absolute;
	z-index: 10;
}

@media screen and (max-width: 1024px) {
	.pulsing {
		width: 50px;
		height: 50px;
		opacity: 1;
	}
	.pulsing-mail {
		width: 50px;
		height: 50px;
		opacity: 1;
	}
}

@media screen and (max-width: 500px) {
	.button-area {
		top: 42%;
	}
	.btn-mail {
		top: 5%;
		left: -80%;
		position: absolute;
	}
}

@media screen and (max-width: 375px) and (max-height: 812px) {
	.pulsing {
		width: 30px;
		height: 30px;
	}
	.pulsing-mail {
		width: 30px;
		height: 30px;
	}
	.btn-linkedin {
		top: 5%;
	}
}

.buttonCircle {
	align-items: center;
	background-color: #1b2a2f;
	border-radius: 50%;
	color: white;
	display: inline-flex;
	height: 10rem;
	justify-content: center;
	padding: 1rem;
	text-align: center;
	width: 10rem;
	animation-name: out;
	animation-duration: 0.5s;
}
.buttonCircle:hover {
	color: orange;
	-webkit-transform: rotate(-20deg);
	transform: rotate(-20deg);
	transition: 0.2s ease-out;
	cursor: pointer;
	animation: in;
	animation-duration: 0.5s;
}

@keyframes in {
	from {
		-webkit-transform: rotate(0deg);
	}
	to {
		-webkit-transform: rotate(-20deg);
	}
}

@keyframes out {
	0% {
		-webkit-transform: rotate(-20deg);
	}
	100% {
		-webkit-transform: rotate(0deg);
	}
}
