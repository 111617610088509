.overflow-hidden {
	overflow: hidden;
}

@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}

.main-body-about {
	width: 100%;
	min-height: 101vh;
	//background: linear-gradient(#090d1d, #e6ebff, #e6ebff);
	background-color: #1b2a2f;
	background-position: 100% 10%;
	// background: linear-gradient(-45deg, #1a68fa, #090d1d, #090d1d, #fd88ea);
	background-size: 100% 200%;
	// animation: gradient 13s ease infinite;
}

.image-fixed {
	width: 100%;
	height: 110vh;
	position: fixed;
	background-color: white;
	z-index: -1;
}

.text-info-forest {
	color: #ffc679 !important;
	font-size: 5rem;
	padding: 0;
}

.text-fixed {
	position: fixed;
	color: #f9b449;
	text-align: center;
	top: 20%;
	font-size: 3rem;
	padding: 0 25rem;
	text-transform: uppercase;
	-webkit-text-stroke: 0.5px #000;
	font-weight: 700;
	font-family: 'Libre Baskerville', serif;
	z-index: -1;
}

.text-fixed-box {
	height: 100%;
	width: 100%;
	z-index: -1;
	position: fixed;
}

.image-et {
	width: 5%;
	left: 55%;
	position: relative;
	margin-top: 6rem;
}

.fixed-image-spacing {
	width: 100%;
	height: 100vh;
	position: relative;
	background-color: transparent;
}

.forest-img {
	width: 100%;
	height: 80vh;
	position: relative;
	top: 10vh;
	z-index: -1;
}

.grad-img {
	border-radius: 5rem;
	width: 20%;
	position: absolute;
	right: 20rem;
	top: 15rem;
	box-shadow: 0px 0px 20px 0px #dd8bc0;
}

.about-box {
	padding: 5% 10%;
	padding-top: 30vh;
	position: relative;
	display: flex;
}

.about-box-more {
	position: relative;
	display: flex;
	padding: 0rem 45%;
	padding-left: 10%;
}

.slide-down {
	width: 50%;
	top: 15%;
	position: relative !important;
	overflow: hidden;
	height: fit-content;
}

.forest-info-text {
	padding: 7% 45%;
	padding-left: 20%;
	color: white !important;
}

.forest-info-text2 {
	padding: 7% 45%;
	padding-right: 20%;
	color: white !important;
}

.project-img {
	border-radius: 5rem;
	width: 20%;
	position: absolute;
	right: 20rem;
	top: 5rem;
}

.video-label {
	color: white !important;
}

.video-responsive {
	width: auto;
	height: auto;
}

.pdfLink {
	position: relative;
	padding: 20px 30px;
	width: 12rem;
	height: 4rem;
	z-index: 2;
	font-size: 1.2rem;
	display: block;
	margin: 0 auto;
	color: white;
	display: flex;
	justify-content: center;
	align-items: center;
	text-decoration: none;
}

.pdfLink::before {
	content: '';
	border: 1px solid #000;
	z-index: 1;
	border-radius: 50%;
	display: block;
	margin: -4px 2px;
	position: absolute;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	transform: rotate(-10deg);
	-webkit-transform: rotate(-10deg);
	-ms-transform: rotate(-10deg);
	transition: transform 2.4s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.pdfLink::after {
	content: '';
	border: 1px solid #000;
	z-index: 1;
	border-radius: 50%;
	display: block;
	margin: -4px 2px;
	position: absolute;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
}

.pdfLink:hover::before {
	transform: rotate(-20deg);
	-webkit-transform: rotate(-20deg);
	-ms-transform: rotate(-20deg);
	transition: transform 0.3s;
}

.pdfLink:hover::after {
	transform: rotate(4deg);
	-webkit-transform: rotate(4deg);
	-ms-transform: rotate(4deg);
	transition: transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.project-img2 {
	border-radius: 5rem;
	width: 20%;
	position: absolute;
	left: 20rem;
	top: 5rem;
}

.sea-hero {
	color: lightskyblue;
	text-decoration: none !important;
}

.slide-down-uni {
	width: 50%;
	top: 50%;
	position: relative !important;
	overflow: hidden;
	height: fit-content;
}

.slide-right {
	width: 50%;
	position: relative;
	overflow: hidden;
}

.slide-left {
	position: relative;
	overflow: hidden;
}

.slide-right h2 {
	animation: 2s slide-right 2s forwards;
	transform: translateX(-100%);
	font-weight: 400;
	font-family: 'Poppins', sans-serif;
	font-size: 2vw;
	color: white;
}

.slide-left h2 {
	animation: 4s slide-right 2s forwards;
	transform: translateX(100%);
	font-family: 'Poppins', sans-serif;
	font-weight: 400;
	font-size: 1vw;
	color: white;
}

.year {
	animation: 2s slide-down 2s forwards;
	transform: translateY(-100%);
	width: 600px;
	font-family: 'Poppins', sans-serif;
	float: left;
	font-size: 2vw;
	color: white;
	font-weight: 700 !important;
}

@keyframes slide-right {
	to {
		transform: translateX(0);
	}
}

@keyframes slide-down {
	to {
		transform: translateY(0);
	}
}

@media screen and (max-width: 1600px) {
	.slide-right h2 {
		transition: font-size 2s;
		font-size: 2.2rem;
	}
	.slide-left h2 {
		transition: font-size 2s;
		font-size: 1.1rem;
	}
	.about-box-more {
		padding: 0rem 32rem;
		padding-left: 10rem;
	}
	.grad-img {
		right: 12rem;
	}
	.text-fixed {
		transition: font-size 2s;
		font-size: 2rem;
	}
	.project-img {
		width: 25%;
		right: 20vh;
		top: 3rem;
	}
	.project-img2 {
		width: 25%;
		left: 20vh;
		top: 3rem;
	}
}

@media screen and (max-width: 1300px) {
	.slide-right h2 {
		font-size: 2rem;
	}
	.slide-left h2 {
		font-size: 0.9rem;
	}
	.about-box-more {
		padding: 0rem 30rem;
		padding-left: 10rem;
	}
	.grad-img {
		right: 10rem;
	}
	.text-fixed {
		padding: 0rem 15rem;
	}

	.project-img {
		width: 25%;

		right: 20vh;
		top: 3rem;
	}

	.project-img2 {
		width: 25%;

		left: 20vh;
		top: 3rem;
	}
}

@media screen and (max-width: 1100px) {
	.slide-right h2 {
		font-size: 1.8rem;
	}
	.slide-left h2 {
		font-size: 0.9rem;
	}
	.about-box-more {
		padding: 0rem 20rem;
		padding-left: 10rem;
		flex-direction: column;
	}
	.grad-img {
		right: 20%;
	}
	.project-img {
		width: 35%;
		right: 0vh;
		top: 3rem;
	}
	.project-img2 {
		width: 35%;
		left: 0vh;
		top: 3rem;
	}
}

//IPAD
@media screen and (max-width: 1024px) and (min-height: 1366px) {
	.about-box-more {
		top: 20vh;
	}
	.grad-img {
		right: 5rem;
		top: 30vh;
		width: 35%;
	}
	.slide-right h2 {
		font-size: 2rem;
	}
	.slide-left h2 {
		font-size: 1.5rem;
	}
	.text-fixed {
		top: 30vh;
	}
	.forest-info-text {
		padding: 5rem 25rem;
		padding-left: 0rem;
	}
	.project-img {
		width: 35%;
		right: 0vh;
		top: 3rem;
	}
	.forest-info-text2 {
		padding: 5rem 25rem;
		padding-right: 0rem;
	}
	.project-img2 {
		width: 35%;
		left: 0vh;
		top: 3rem;
	}
	.text-fixed {
		padding: 0rem 5rem;
		font-size: 2.5rem;
	}
	.image-fixed {
		width: 150vh;
		height: 103vh;
		left: -30rem;
	}
}

@media screen and (max-width: 850px) {
	.slide-right h2 {
		font-size: 1.5rem;
	}
	.slide-left h2 {
		font-size: 1rem;
	}
	.about-box-more {
		padding: 0rem 10rem;
		padding-left: 5rem;
	}
	.grad-img {
		width: 25%;
	}
	.text-fixed {
		padding: 0rem 10%;
	}
	.year {
		font-size: 1.5rem;
	}
	.forest-info-text {
		padding: 8% 40%;
		padding-left: 1%;
	}
	.forest-info-text2 {
		padding: 8% 40%;
		padding-right: 1%;
	}
	.text-info-forest {
		transition: font-size 2s;
		font-size: 3rem;
	}
	.forest-img {
		width: 100vh;
	}
}
@media screen and (max-width: 768px) {
	.image-fixed {
		width: 100vh;
		height: 110vh;
		position: fixed;
		background-color: white;
		z-index: -1;
	}
}

@media screen and (max-width: 600px) {
	.about-box {
		padding: 0rem 4rem;
		padding-top: 15rem;
	}
	.text-fixed {
		padding: 0rem 5%;
	}
	.text-info-forest {
		font-size: 2rem;
	}
}

@media screen and (max-width: 500px) {
	.year {
		font-size: 1rem;
	}
	.text-fixed {
		font-size: 1.5rem;
	}
	.about-box {
		padding: 0rem 15%;
		padding-top: 100%;
	}
	.grad-img {
		width: 50%;
		top: 6rem;
		right: 25%;
	}
	.slide-right {
		width: 100%;
	}
	.slide-right h2 {
		font-size: 5vmin;
	}
	.slide-left h2 {
		font-size: 0.8rem;
	}
	.about-box-more {
		padding: 0rem 4rem;
	}
	.image-fixed {
		left: -200px;
	}
	.text-fixed {
		-webkit-text-stroke: 0.2px #000;
	}
}
//IPHONE X
@media screen and (max-width: 375px) and (max-height: 812px) {
	.slide-right h2 {
		font-size: 1rem;
	}
	.slide-left h2 {
		font-size: 0.6rem;
	}
	// .about-box {
	// 	padding: 0rem 1rem;
	// 	padding-top: 10rem;
	// }
	// .about-box-more {
	// 	padding: 5rem 2rem;
	// 	padding-left: 1rem;
	// }
	// .grad-img {
	// 	right: 2rem;
	// 	width: 40%;
	// }
	.text-info-forest {
		font-size: 1rem;
	}
}
@media screen and (max-width: 360px) {
	.text-info-forest {
		font-size: 1.8rem;
	}
}

@media screen and (max-width: 360px) {
	.about-box-more {
		padding: 2rem 2rem;
	}
}
